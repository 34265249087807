import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom';

import '../assets/css/footer.css';
import Logo from '../assets/images/logo.png'


export const Footer = () => {
  return (

    <footer className="wow fadeInDown ">
      <div className="foot-2">
        <div className="container">
          <div className="inner-foot-2">
            <figure><Link to={''}><img src={Logo} alt="" /></Link></figure>

            <p>Hash Digitech is specifically tailored to providing top-notch digital marketing services that are on point with leading international business habits. We'll help lift your brand by driving traffic towards your website and business. We are a digital marketing agency in Delhi, serving clients from all walks of life.</p>
            <li>

            </li>
          </div>
          <div className="inner-foot-2">
            <ul className="px-0">
              <h2>Quick Links</h2>
              <li><Link to={'/'}>Home</Link></li>
              <li><Link to={'/about'}>About Us</Link></li>
              <li><Link to={'/contact'}>Contact Us</Link></li>
              {/* <li><Link to={''}>Blog</Link></li> */}
            </ul>
          </div>
          <div className="inner-foot-2">
            <ul className="px-0">
              <h2>Services</h2>
              <li><Link to={'/seo'}>Social Engine Optimization</Link></li>
              <li><Link to={'/ppc'}>Pay Per Click</Link></li>
              <li><Link to={'/smm'}>Social Media Marketing</Link></li>
              <li><Link to={'/influencer-marketing'}>Influencer Marketing
              </Link></li>
              <li><Link to={'/web-design-and-development'}>Website Design & Development</Link></li>
              <li><Link to={'/graphic'}>Graphic Design</Link></li>
              <li><Link to={'/video-production'}>Video Production</Link></li>
              <li><Link to={'/ecommerce-solution'}>Ecommerce Solution</Link></li>
            </ul>
          </div>

          <div className="inner-foot-2">
            <ul className="px-0">
              <h2>contact</h2>
              <li><Link><i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp; 178, 1st Floor, Aakash Nagar, Near Ghanshyam Farm House, Ghaziabad-201015 </Link></li>
              <li><Link><i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp; 205, 2nd Floor, Radha chambers, vikaspuri, New Delhi-110018 </Link></li>



              <li><Link to={'tel:919599970508'}><i className="fa fa-phone" aria-hidden="true"></i>&nbsp; +91-95 999 70508</Link>
              </li>

              <li><Link to={'mailto: hashdigitech@gmail.com'}><i className="fa fa-envelope" aria-hidden="true"></i>
                &nbsp; hashdigitech@gmail.com</Link></li>
            </ul>
          </div>
        </div>
        <div className=" foot-copyright ">
          <p><span className="cpy-txt">Copyright</span> @ 2023 Hashdigitech All Rights Reserved</p>
        </div>
      </div>
    </footer>
  )

}